:root {
  --primary-color:#3213BE;
  --header-bg: transparent;
}

#parallax {
  background: transparent;
  width: 300px;
  position: relative;
  height: 200px;
  
  svg#cat {
    position: absolute;
    bottom: 0;
    left: 0;
    transform: translateX(-50%);
  }
  svg#meow {
    position: absolute;
    left: 50%;
    top: 48%;
    transform: translate(-50%, -50%);
  }
}

#fish-cursor {
  position: fixed;
  transform: rotate(-135deg);
  z-index: 2;
  left: 0;
  top: 0;
  -webkit-text-stroke: 1px #fff;
}